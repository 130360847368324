import { Currency } from "./currency";

export enum StatisticGroupField {
  DATE = "DATE",
  USER = "USER",
  MEDIABUYER = "MEDIABUYER",
  TEAMLEAD = "TEAMLEAD",
}

export interface StatisticByType {
  date?: string;
  group: string;
  totalIssueFeeAmount?: number;
  totalCommissionFeeAmount?: number;
  totalSpendAmount: number;
  totalRefundAmount: number;
  totalFeeAmount: number;
  totalRefillAmount: number;
  totalHoldAmount: number;
  totalPendingAmount: number;
  numTransaction: number;
  numDeclinedTransaction: number;
  numRefillTransaction: number;
  numAuthorizationTransaction: number;
  numPendingTransaction: number;
  profile?: {
    email: string;
    firstName?: string;
    lastName?: string;
  };
}

export interface StatisticByStatus {
  date?: string;
  group: string;
  numPendingTransaction: number;
  numDeclinedTransaction: number;
  numRefundTransaction: number;
  numApprovedTransaction: number;
  totalPendingAmount: number;
  totalDeclinedAmount: number;
  totalRefundAmount: number;
  totalApprovedAmount: number;
  profile?: {
    email: string;
    firstName?: string;
    lastName?: string;
  };
}

export interface StatisticSummary {
  date?: string;
  group: string;
  numPendingTransaction: number;
  numDeclinedTransaction: number;
  numRefundTransaction: number;
  numApprovedTransaction: number;
}

export type StatisticByTypeData = Partial<Record<Currency, StatisticByType[]>>;

export type StatisticByStatusData = Partial<
  Record<Currency, StatisticByStatus[]>
>;

export interface StatisticByType {
  data: StatisticByTypeData;
}

export interface StatisticByStatus {
  data: StatisticByStatusData;
  summary: StatisticSummary;
}

export interface StatisticDeclines {
  declineRate: number;
  declineAmount: number;
  declineRateByCount: number;
}
