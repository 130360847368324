export async function downloadXLSX<T>({
  items,
  headers,
}: {
  items: T[];
  headers: Record<string, string>;
}): Promise<void> {
  const { utils, writeFileXLSX } = await import("xlsx");

  const rows = items.map((item) => {
    const obj: Record<string, string> = {};

    for (const key in headers) {
      if (Object.prototype.hasOwnProperty.call(headers, key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        obj[headers[key]] = item[key] ?? "";
      }
    }

    return obj;
  });

  const worksheet = utils.json_to_sheet(rows);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet);

  worksheet["!cols"] = Object.values(headers).map((header) => {
    return {
      wch: Math.max(
        ...rows
          .map((row) => row[header].toString().length + 2)
          .concat(header.length + 2)
      ),
    };
  });

  writeFileXLSX(workbook, `${Date.now()}.xlsx`, { compression: true });
}
