export function downloadCSV<T>({
  items,
  headers,
}: {
  items: T[];
  headers: Record<string, string>;
}): void {
  const keys = Object.keys(headers);
  const content = items.reduce((acc, item, index) => {
    if (index === 0) {
      acc += Object.values(headers)
        .reduce<string[]>((acc, header) => {
          acc.push(JSON.stringify(header));

          return acc;
        }, [])
        .join(",");
    }

    acc += "\n";

    acc += keys
      .map((key) => {
        const prop = key as keyof T;

        return item[prop] === undefined || item[prop] === null
          ? "-"
          : JSON.stringify(item[prop]).replace(/\\n/g, " ").trim();
      })
      .join(",");

    return acc;
  }, "");

  const blob = new Blob([content], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const anchorEl = document.createElement("a");

  anchorEl.download = `${Date.now()}.csv`;
  anchorEl.href = url;

  anchorEl.click();

  window.setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
}
