export enum Period {
  TODAY = "today",
  YESTERDAY = "yesterday",
  LAST_3_DAYS = "last_3_days",
  LAST_7_DAYS = "last_7_days",
  LAST_14_DAYS = "last_14_days",
  CURRENT_MONTH = "current_month",
  PREV_MONTH = "prev_month",
  LAST_MONTH = "last_month",
  LAST_3_MONTHS = "last_3_months",
  LAST_6_MONTHS = "last_6_months",
  CUSTOM = "custom",
}
