import { Period } from "@/types/datepicker";

export function getTimezoneHours(): number {
  const date = new Date();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  const dateWithoutZone = new Date(date.toISOString().split(".")[0]);

  return Math.floor(
    (date.getTime() - dateWithoutZone.getTime()) / 1e3 / 60 / 60
  );
}

export function getTimezoneHoursInSeconds(): number {
  return getTimezoneHours() * 60 * 60;
}

export function formatDatePickerVal(
  date: number,
  options: { utc?: boolean } = {}
): string {
  const { utc = false } = options;
  const newDate = new Date(date);

  if (!utc) {
    return newDate
      .toLocaleString("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split(".")
      .reverse()
      .join("-");
  }

  return newDate.toISOString().slice(0, 10);
}

export function parseDatePickerVal(
  date: string,
  options: { utc?: boolean } = {}
): Date {
  const { utc = false } = options;
  const [year, month, day] = date.split("-");
  const parsedDate = new Date(+year, +month - 1, +day);

  if (utc) {
    parsedDate.setHours(getTimezoneHours());
  }

  return parsedDate;
}

export function periodRangeStartTimestamp(value: Date | number): number {
  const startDate = new Date(value);

  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  return Math.floor(startDate.getTime() / 1000);
}

export function periodRangeEndTimestamp(value: Date | number): number {
  const endDate = new Date(value);

  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);

  return Math.floor(endDate.getTime() / 1000);
}

export function formatTimePickerVal({
  hours,
  minutes,
}: {
  hours: number;
  minutes: number;
}): string {
  const withZero = (value: number) =>
    value >= 0 && value <= 9 ? `0${value}` : value;

  return `${withZero(hours)}:${withZero(minutes)}`;
}

export function parsedTimePickerVal(value: string): {
  hours: number;
  minutes: number;
} {
  const [hours, minutes] = value.split(":");

  return {
    hours: +hours,
    minutes: +minutes,
  };
}

export function getPeriodDate(period: Period): {
  start: number;
  end: number;
} {
  const dateStart = new Date();
  const dateEnd = new Date();

  let start = dateStart.getTime();
  let end = dateEnd.getTime();

  switch (period) {
    case Period.YESTERDAY: {
      start = dateStart.setDate(dateStart.getDate() - 1);
      end = dateEnd.setDate(dateEnd.getDate() - 1);

      break;
    }

    case Period.LAST_3_DAYS: {
      start = dateStart.setDate(dateStart.getDate() - 2);
      end = dateEnd.getTime();

      break;
    }

    case Period.LAST_7_DAYS: {
      start = dateStart.setDate(dateStart.getDate() - 6);
      end = dateEnd.getTime();

      break;
    }

    case Period.LAST_14_DAYS: {
      start = dateStart.setDate(dateStart.getDate() - 13);
      end = dateEnd.getTime();

      break;
    }

    case Period.CURRENT_MONTH: {
      start = dateStart.setDate(1);
      end = dateEnd.getTime();

      break;
    }

    case Period.LAST_MONTH: {
      start = dateStart.setDate(dateStart.getDate() - 30);
      end = dateEnd.getTime();

      break;
    }

    case Period.PREV_MONTH: {
      dateStart.setMonth(dateStart.getMonth() - 1);
      dateEnd.setDate(0);

      start = dateStart.setDate(1);
      end = dateEnd.getTime();

      break;
    }

    case Period.LAST_3_MONTHS: {
      start = dateStart.setDate(dateStart.getDate() - 3 * 30);
      end = dateEnd.getTime();

      break;
    }

    case Period.LAST_6_MONTHS: {
      start = dateStart.setDate(dateStart.getDate() - 6 * 30);
      end = dateEnd.getTime();

      break;
    }

    default: {
      start = dateEnd.getTime();
      end = dateEnd.getTime();

      break;
    }
  }

  return {
    start,
    end,
  };
}

export function getFormatedPeriodDate(period: Period): {
  start: string;
  end: string;
} {
  const { start, end } = getPeriodDate(period);

  return {
    start: formatDatePickerVal(start),
    end: formatDatePickerVal(end),
  };
}
